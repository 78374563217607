import { roleAdmin, roleManager, roleSuperAdmin } from '@/mixins/roles';

const HotelsRoomParameters = () => import('@/pages/hotels/roomParameters.vue');

export default [
  {
    path: '/hotels/room-parameters',
    name: 'HotelsRoomParameters',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Параметры',
      ],
    },
    component: HotelsRoomParameters,
  },
  {
    path: '/hotels/parameters/:id',
    name: 'HotelsRoomParametersForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Параметры',
      ],
    },
    component: HotelsRoomParameters,
  },
];
