// src/store/modules/hotels_periods.js

import standardAPI from '@/api/standard_items'; // Keep this if you plan to switch back to real API later

const responseUrl = '/hotels/periods';

// Mock Data
const mockDates = [
  {
    id: 1,
    title_ru: 'Период Весна',
    title_en: 'Spring Period',
    alias: 'spring-period',
    start_period: '2024-03-01',
    end_period: '2024-05-31',
    is_active: true,
  },
  {
    id: 2,
    title_ru: 'Период Лето',
    title_en: 'Summer Period',
    alias: 'summer-period',
    start_period: '2024-06-01',
    end_period: '2024-08-31',
    is_active: true,
  },
  {
    id: 3,
    title_ru: 'Период Осень',
    title_en: 'Autumn Period',
    alias: 'autumn-period',
    start_period: '2024-09-01',
    end_period: '2024-11-30',
    is_active: false,
  },
  {
    id: 4,
    title_ru: 'Период Зима',
    title_en: 'Winter Period',
    alias: 'winter-period',
    start_period: '2024-12-01',
    end_period: '2024-02-28',
    is_active: true,
  },
];

const state = {
  periods: [],
  periods_MetaData: {
    meta: {
      current_page: 1,
      per_page: 10,
      total: mockDates.length,
      last_page: 1,
    },
    links: {
      previous: null,
      next: null,
    },
  },
  periods_status: '',
  periods_page: 1,
  periods_perPage: 10,
};

const getters = {
  get_periods: (state) => state.periods,
  get_periods_status: (state) => state.periods_status,
};

const actions = {
  fetch_periods_silent({ commit }) {
    console.log("🔥 fetch_periods_silent вызван");

    commit("DATA_PERIODS_REQUEST");

    const token = localStorage.getItem("auth_token_default");
    if (!token) {
      console.error("Ошибка: токен не найден");
      commit("DATA_PERIODS_ERROR");
      return Promise.reject(new Error("Token not provided"));
    }

    const preparedOptions = {
      url: "/hotels/periods",
      token: token,
    };

    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        console.log("🔥 Ответ API:", response);

        if (response && response.data) {
          commit("DATA_PERIODS_UPDATE_SUCCESS", {
            data: response.data,
            meta: response.meta || { current_page: 1, per_page: 10, total: 0 },
            links: response.links || {},
          });

          commit("DATA_PERIODS_READY");
          console.log("✅ Данные после коммита в Vuex:", response.data);
          resolve(response); // ✅ Возвращаем данные, как в `fetch_periods`
        } else {
          console.warn("⚠ API не вернул данных");
          commit("DATA_PERIODS_READY");
          reject(new Error("No data from API"));
        }
      }, preparedOptions);
    });
  },
  // Set the current page number
  set_current_page({ commit }, page) {
    commit('SET_CURRENT_PAGE', page);
    // Fetch data again if needed when page changes
    commit('DATA_PERIODS_REQUEST');
    commit('DATA_PERIODS_UPDATE_SUCCESS', { data: mockDates.slice(0, state.periods_perPage), meta: state.periods_MetaData.meta, links: state.periods_MetaData.links });
    commit('DATA_PERIODS_READY');
  },

  // Set the number of items per page
  set_perPage({ commit }, max) {
    commit('SET_PER_PAGE', max);
    // Fetch data again if needed when perPage changes
    commit('DATA_PERIODS_REQUEST');
    commit('DATA_PERIODS_UPDATE_SUCCESS', { data: mockDates.slice(0, max), meta: { ...state.periods_MetaData.meta, per_page: max }, links: state.periods_MetaData.links });
    commit('DATA_PERIODS_READY');
  },

  // Fetch periods with options (e.g., pagination, filters)
  fetch_periods({ commit }, getOptions) {
    console.log('fetch_periods вызван');

    commit('DATA_PERIODS_REQUEST');

    const token = localStorage.getItem('auth_token_default');
    if (!token) {
      console.error('Ошибка: токен не найден');
      commit('DATA_PERIODS_ERROR');
      return Promise.reject(new Error('Token not provided'));
    }

    const preparedOptions = {
      getParams: getOptions.pageOptions,
      url: '/hotels/periods',
      token: token,
    };

    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        console.log('Ответ API:', response);

        if (response && response.data) {
          commit('DATA_PERIODS_UPDATE_SUCCESS', {
            data: response.data, // ✅ Данные из API
            meta: response.meta || { current_page: 1, per_page: 10, total: 0 },
            links: response.links || {},
          });

          commit('DATA_PERIODS_READY');
          console.log('Данные после коммита в Vuex:', response.data);
          resolve(response);
        } else {
          console.warn('⚠ Нет данных в ответе API');
          commit('DATA_PERIODS_READY');
          reject(response);
        }
      }, preparedOptions);
    });
  }

,
  // Add a new period period
  add_period ({ commit, dispatch }, itemData) {
    commit('DATA_PERIODS_REQUEST');
    const token = localStorage.getItem('auth_token_default');

    if (!token) {
      console.error('Ошибка: токен не найден');
      commit('DATA_PERIODS_ERROR');
      return Promise.reject(new Error('Token not provided'));
    }

    console.log('TOKEN: ', token);
    console.log('Исходные данные:', itemData);

    // Создаем правильную структуру
    const preparedData = {
      itemData: {
        token: token,
        formData: itemData, // теперь formData передается корректно
      },
      url: responseUrl,
    };

    console.log('Подготовленные данные для API:', preparedData);

    return new Promise((resolve, reject) => {
      standardAPI.add_item(preparedData, (response) => {
        console.log('Ответ сервера:', response);
        if (response.status === 'created') {
          commit('DATA_PERIODS_REQUEST');
          resolve(response);
        } else {
          commit('DATA_PERIODS_READY');
          reject(response);
        }
      });
    });
  }
  ,
  edit_period({ commit }, itemData) {
    console.log('edit_period itemData (до форматирования):', JSON.stringify(itemData, null, 2));

    commit('DATA_PERIODS_REQUEST');

    const authToken = localStorage.getItem('auth_token_default');

console.log('Token: ', authToken)
    // Оборачиваем `itemData` в `formData`, как ожидает `edit_item`
    const preparedData = {
      itemData: {
        formData: itemData,
        token: authToken,
      },
      url: '/hotels/periods/',
      token: authToken,

    };

    console.log('preparedData перед отправкой в edit_item:', JSON.stringify(preparedData, null, 2));

    return new Promise((resolve, reject) => {
      standardAPI.edit_item(preparedData, (response) => {
        console.log('Ответ сервера в edit_period:', response);

        if (response.status === 'updated') {
          commit('DATA_PERIODS_UPDATE_SUCCESS', response.data);
          commit('DATA_PERIODS_READY');
          resolve(response);
        } else {
          commit('DATA_PERIODS_READY');
          reject(response);
        }
      });
    });
  }

,
  // Remove one or multiple period periods
  remove_periods({ commit }, itemData) {
    commit('DATA_PERIODS_REQUEST');
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const idsToRemove = itemData; // Expecting an array of IDs
        const initialLength = mockDates.length;
        mockDates = mockDates.filter((period) => !idsToRemove.includes(period.id));
        if (mockDates.length < initialLength) {
          commit('DATA_PERIODS_UPDATE_SUCCESS', { data: mockDates, meta: { ...state.periods_MetaData.meta, total: mockDates.length }, links: state.periods_MetaData.links });
          commit('DATA_PERIODS_READY');
          resolve({ status: 'deleted' });
        } else {
          commit('DATA_PERIODS_READY');
          reject({ status: 'error', data: 'No periods were deleted' });
        }
      }, 300);
    });
  },

  // Reorder a single period period
  reorder_period({ commit }, orderData) {
    commit('DATA_PERIODS_REQUEST');
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const { id, newPosition } = orderData; // Expecting { id, newPosition }
        const index = mockDates.findIndex((period) => period.id === id);
        if (index !== -1 && newPosition >= 0 && newPosition < mockDates.length) {
          const [movedDate] = mockDates.splice(index, 1);
          mockDates.splice(newPosition, 0, movedDate);
          commit('DATA_PERIODS_UPDATE_SUCCESS', { data: mockDates, meta: state.periods_MetaData.meta, links: state.periods_MetaData.links });
          commit('DATA_PERIODS_READY');
          resolve({ status: 'reorder', data: mockDates });
        } else {
          commit('DATA_PERIODS_READY');
          reject({ status: 'error', data: 'Invalid reorder parameters' });
        }
      }, 300);
    });
  },

  // Set the order of period periods (bulk reorder)
  set_period_order({ commit }, itemData) {
    commit('DATA_PERIODS_REQUEST');
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // Assuming itemData is the entire ordered array
        mockDates = [...itemData];
        commit('DATA_PERIODS_UPDATE_SUCCESS', { data: mockDates, meta: state.periods_MetaData.meta, links: state.periods_MetaData.links });
        commit('DATA_PERIODS_READY');
        resolve({ status: 'reorder', data: mockDates });
      }, 300);
    });
  },
};

const mutations = {
  SET_CURRENT_PAGE: (state, pageNumber) => {
    state.periods_page = pageNumber;
  },
  SET_PER_PAGE: (state, max) => {
    state.periods_perPage = max;
    state.periods_MetaData.meta.per_page = max;
  },
  DATA_PERIODS_READY: (state) => {
    state.periods_status = 'ready';
  },
  DATA_PERIODS_REQUEST: (state) => {
    state.periods_status = 'loading';
  },
  DATA_PERIODS_UPDATE_SUCCESS: (state, response) => {
      if (response && response.meta) {
        state.periods_MetaData.meta = response.meta;
        state.periods_MetaData.links = response.links;
      }

      console.log('🔥 Данные перед коммитом в Vuex:', response.data);

      state.periods = Array.isArray(response.data) ? response.data : []; // ✅ Всегда массив

      console.log('✅ Данные после мутации:', state.periods);

      state.periods_status = 'success';
  },
  DATA_PERIODS_ERROR: (state) => {
    state.periods_status = 'error';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
