import { roleAdmin, roleManager, roleSuperAdmin } from '@/mixins/roles';

const HotelsTransfers = () => import('@/pages/hotels/transfers.vue');

export default [
  {
    path: '/hotels/transfers',
    name: 'HotelsTransfers',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Transfers',
      ],
    },
    component: HotelsTransfers,
  },
  {
    path: '/hotels/transfers/:id',
    name: 'HotelsTransfersForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Transfer',
      ],
    },
    component: HotelsTransfers,
  },
];
