import standardAPI from '@/api/standard_items';

const state = {
  packages: [],
  packages_status: '',
  packages_MetaData: {},
  packages_page: 1,
  packages_perPage: 10,
};

const responseUrl = '/hotels/packages';

const getters = {};

const actions = {
  set_current_page ({ commit }, page) {
    commit('SET_CURRENT_PAGE', page);
  },
  set_perPage ({ commit }, max) {
    commit('SET_PER_PAGE', max);
  },
  set_Period({ commit }, periodData) {
    console.log("📦 Данные перед Vuex (set_Period):", periodData);

    commit("ADD_PERIOD_TO_DRAFT", periodData);

    console.log("✅ После Vuex обновления: ", state.packages);
  },
  fetch_packages ({ commit }, getOptions) {
    commit('PACKAGES_REQUEST');
    const preparedOptions = {
      getParams: getOptions.pageOptions,
      url: responseUrl,
      token: getOptions.token,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        console.log("📥 Данные из API перед `PACKAGES_SUCCESS`:", response);
        try {
          if (response && response.status !== 404) {
            commit('PACKAGES_SUCCESS', response);
            commit('PACKAGES_READY');
            resolve(response);
          } else if (response.status === 404) {
            commit('PACKAGES_READY');
            reject(response);
          } else {
            commit('PACKAGES_ERROR');
            reject(response);
          }
        } catch (error) {
          console.log(error);
        }
      }, preparedOptions);
    });
  },
  fetch_packages_silent ({ commit }, getOptions) {
    const preparedOptions = {
      getParams: getOptions.pageOptions,
      url: responseUrl,
      token: getOptions.token,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        try {
          if (response && response.status !== 404) {
            resolve(response);
          } else if (response.status === 404) {
            reject(response);
          } else {
            reject(response);
          }
        } catch (error) {
          console.log(error);
        }
      }, preparedOptions);
    });
  },
  add_package ({ commit, dispatch }, itemData) {
    commit('PACKAGES_REQUEST');
    const preparedData = {
      itemData,
      url: responseUrl,
    };
    console.log("Package data, ", preparedData);
    return new Promise((resolve, reject) => {
      standardAPI.add_item(preparedData, (response) => {
        if (response.status === 'created') {
          resolve(response);
        } else {
          commit('PACKAGES_READY');
          reject(response);
        }
      });
    });
  },
  edit_package ({ commit, dispatch }, itemData) {
    commit('PACKAGES_REQUEST');
    const preparedData = {
      itemData,
      url: responseUrl + '/',
    };
    return new Promise((resolve, reject) => {
      standardAPI.edit_item(preparedData, (response) => {
        if (response.status === 'updated') {
          resolve(response);
        } else {
          commit('PACKAGES_READY');
          reject(response);
        }
      });
    });
  },
  remove_package ({ commit, dispatch }, itemData) {
    commit('PACKAGES_REQUEST');
    const preparedData = {
      itemData,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.remove_items(preparedData, (response) => {
        if (response.status === 'deleted') {
          resolve(response);
        } else {
          commit('PACKAGES_READY');
          reject(response);
        }
      });
    });
  },
  reorder_package ({ commit, dispatch }, orderData) {
    const preparedData = {
      orderData,
      url: responseUrl + '/reorder',
    };
    return new Promise((resolve, reject) => {
      standardAPI.reorder_item(preparedData, (response) => {
        if (response.status === 'reorder') {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
  set_package_order ({ commit, dispatch }, itemData) {
    const preparedData = {
      itemData,
      url: responseUrl + '/set-reorder-index/' + itemData.id,
    };
    return new Promise((resolve, reject) => {
      standardAPI.reorder_item_number(preparedData, (response) => {
        if (response.status === 'reorder') {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
};

const mutations = {
  SET_CURRENT_PAGE: (state, pageNumber) => {
    state.packages_page = pageNumber;
  },
  SET_PER_PAGE: (state, max) => {
    state.packages_perPage = max;
  },
  PACKAGES_READY: (state) => {
    state.packages_status = 'ready';
  },
  PACKAGES_REQUEST: (state) => {
    state.packages_status = 'loading';
  },
  PACKAGES_SUCCESS: (state, response) => {
    if (response && response.meta) {
      state.packages_MetaData.meta = response.meta;
      state.packages_MetaData.links = response.links;
    }
    if (response && response.data) {
      state.packages = response.data || [];
    }
    state.packages_status = 'success';
  },
  PACKAGES_ERROR: (state) => {
    state.packages_status = 'error';
  },
  ADD_PERIOD_TO_DRAFT(state, periodData) {
    console.log("🆕 Добавление периода в Vuex:", periodData);
    const index = state.packages.findIndex((p) => p.id === periodData.id);

    if (index !== -1) {
      state.packages[index] = {
        ...state.packages[index],
        ...periodData,
        selectedDuration: periodData.selectedDuration ?? state.packages[index].selectedDuration,
        selectedOutboundFlight: periodData.selectedOutboundFlight ?? state.packages[index].selectedOutboundFlight,
        selectedReturnFlight: periodData.selectedReturnFlight ?? state.packages[index].selectedReturnFlight,
      };

    } else {
      state.packages.push({
        id: periodData.id ?? null,
        name: periodData.name,
        room_id: periodData.room_id ?? null,
        arrival_flight_id: periodData.arrival_flight_id ?? null,
        departure_flight_id: periodData.departure_flight_id ?? null,
        selectedDuration: periodData.selectedDuration ?? null,  // ✅ Добавлено
        selectedOutboundFlight: periodData.selectedOutboundFlight ?? null,  // ✅ Добавлено
        selectedReturnFlight: periodData.selectedReturnFlight ?? null,  // ✅ Добавлено
        price_sngl: periodData.price_sngl ?? null,
        price_double: periodData.price_double ?? null,
      });
    }
  }
  ,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
