import { roleAdmin, roleManager, roleSuperAdmin } from '@/mixins/roles';

const HotelsPackages = () => import('@/pages/hotels/packages.vue');

export default [
  {
    path: '/hotels/packages',
    name: 'HotelsPackages',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Packages',
      ],
    },
    component: HotelsPackages,
  },
  {
    path: '/hotels/packages/:id',
    name: 'HotelsPackagesForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Packages',
      ],
    },
    component: HotelsPackages,
  },
];
