import standardAPI from '@/api/standard_items';

const responseUrl = '/hotels/food-types';

const state = {
  food_types: [],
  food_types_MetaData: {},
  food_types_status: '',
  food_types_page: 1,
  food_types_perPage: 10,
};

const getters = {
  get_food_types: (state) => {
    return state.food_types;
  },
  get_food_types_status: (state) => {
    return state.food_types_status;
  },
};

const actions = {
  set_current_page ({ commit }, page) {
    commit('SET_CURRENT_PAGE', page);
  },
  set_perPage ({ commit }, max) {
    commit('SET_PER_PAGE', max);
  },
  fetch_hotels(callback, getData) {
    const getParams = api.toUrl(getData.getParams);
    const options = {
      token: getData.token,
      url: api.baseURL + '/hotels' + (getParams ? '?' + getParams : ''),
      method: 'get',
    };
    api.standardResponse(options, callback);
  },
  fetch_food_types ({ commit }, getOptions) {
    commit('DATA_FOOD_TYPES_REQUEST');
    const preparedOptions = {
      getParams: getOptions.pageOptions,
      url: responseUrl,
      token: getOptions.token,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        try {
          if (response && response.status !== 404) {
            commit('DATA_FOOD_TYPES_UPDATE_SUCCESS', response);
            commit('DATA_FOOD_TYPES_READY');
            resolve(response);
          } else if (response.status === 404) {
            commit('DATA_FOOD_TYPES_READY');
            reject(response);
          } else {
            commit('DATA_FOOD_TYPES_ERROR');
            reject(response);
          }
        } catch (error) {
          console.log(error);
        }
      }, preparedOptions);
    });
  },
  fetch_food_types_silent ({ commit }, getOptions) {
    const preparedOptions = {
      getParams: getOptions.pageOptions,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.get_items((response) => {
        try {
          if (response && response.status !== 404) {
            resolve(response);
          } else if (response.status === 404) {
            reject(response);
          } else {
            reject(response);
          }
        } catch (error) {
          console.log(error);
        }
      }, preparedOptions);
    });
  },
  add_food_type ({ commit, dispatch }, itemData) {
    console.log('add_food_type')
    commit('DATA_FOOD_TYPES_REQUEST');
    const preparedData = {
      itemData: {
        ...itemData,
      },
      url: responseUrl,
    };
    console.log("The prepared data ADD", preparedData);

    return new Promise((resolve, reject) => {
      standardAPI.add_item(preparedData, (response) => {
        if (response.status === 'created') {
          commit('DATA_FOOD_TYPES_REQUEST');
          resolve(response);
        } else {
          commit('DATA_FOOD_TYPES_READY');
          reject(response);
        }
      });
    });
  },
  edit_food_type ({ commit, dispatch }, itemData) {
    console.log('edit_food_type',`${itemData.id}`)
    commit('DATA_FOOD_TYPES_REQUEST');
    const preparedData = {
      itemData: {
        ...itemData,
      },
      url: `${responseUrl}/`,
    };
    console.log("The prepared data EDIT", preparedData);

    return new Promise((resolve, reject) => {
      standardAPI.edit_item(preparedData, (response) => {
        if (response.status === 'updated') {
          commit('DATA_FOOD_TYPES_REQUEST');
          resolve(response);
        } else {
          commit('DATA_FOOD_TYPES_READY');
          reject(response);
        }
      });
    });
  },
  remove_food_types ({ commit, dispatch }, itemData) {
    commit('DATA_FOOD_TYPES_REQUEST');
    const preparedData = {
      itemData,
      url: responseUrl,
    };
    return new Promise((resolve, reject) => {
      standardAPI.remove_items(preparedData, (response) => {
        if (response.status === 'deleted') {
          commit('DATA_FOOD_TYPES_REQUEST');
          resolve();
        } else {
          commit('DATA_FOOD_TYPES_READY');
          reject(response);
        }
      });
    });
  },
  reorder_food_type ({ commit, dispatch }, orderData) {
    const preparedData = {
      orderData,
      url: responseUrl + '/reorder',
    };
    return new Promise((resolve, reject) => {
      standardAPI.reorder_item(preparedData, (response) => {
        if (response.status === 'reorder') {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
  set_food_type_order ({ commit, dispatch }, itemData) {
    const preparedData = {
      itemData,
      url: responseUrl + '/set-reorder-index/' + itemData.id,
    };
    return new Promise((resolve, reject) => {
      standardAPI.reorder_item_number(preparedData, (response) => {
        if (response.status === 'reorder') {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
  attachHotelsToFoodType({ commit }, { foodTypeId, hotelIds }) {
    commit('DATA_FOOD_TYPES_REQUEST');
    const payload = { hotel_ids: hotelIds };

    return new Promise((resolve, reject) => {
        standardAPI.post(
            `/hotels/food-types/${foodTypeId}/attach-hotels`,
            payload,
            (response) => {
                if (response.status === 'success') {
                    commit('DATA_FOOD_TYPES_READY');
                    resolve(response);
                } else {
                    commit('DATA_FOOD_TYPES_ERROR');
                    reject(response);
                }
            },
            (error) => {
                commit('DATA_FOOD_TYPES_ERROR');
                reject(error);
            }
        );
    });
},
};

const mutations = {
  SET_CURRENT_PAGE: (state, pageNumber) => {
    state.food_types_page = pageNumber;
  },
  SET_PER_PAGE: (state, max) => {
    state.food_types_perPage = max;
  },
  DATA_FOOD_TYPES_READY: (state) => {
    state.food_types_status = 'ready';
  },
  DATA_FOOD_TYPES_REQUEST: (state) => {
    state.food_types_status = 'loading';
  },
  DATA_FOOD_TYPES_UPDATE_SUCCESS: (state, response) => {
    if (response && response.meta) {
      state.food_types_MetaData.meta = response.meta;
      state.food_types_MetaData.links = response.links;
    }
    if (response && response.data) {
      state.food_types = response.data || [];
    }
    state.food_types_status = 'success';
  },
  DATA_FOOD_TYPES_ERROR: (state) => {
    state.food_types_status = 'error';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
