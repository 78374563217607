// src/router/modules/hotels/periods.js

import { roleAdmin, roleManager, roleSuperAdmin } from '@/mixins/roles';

const PeriodsDates = () => import('@/pages/hotels/periods.vue');

export default [
  {
    path: '/hotels/periods',
    name: 'HotelsDates',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Dates Periods',
      ],
    },
    component: PeriodsDates,
  },
  {
    path: '/hotels/periods/:id',
    name: 'PeriodsDatesForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Date Period',
      ],
    },
    component: PeriodsDates,
  },
];
