<template>
  <div>
    <notifications />
    <router-view name="header" />
    <router-view />
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss">
$labelWidth: 250px;

#nprogress .bar {
  background: #eb7e23;
}

.el-popper[role="tooltip"] {
  max-width: $labelWidth;
}

.el-loading-mask {
  z-index: 9 !important;
}
.el-form-items > .el-form-item:last-child {
  margin-bottom: 0 !important;
}
.el-form-item {
  &__label .el-tooltip__trigger {
    margin: calc((40px - 1em) / 2) 0 0 .5em;
  }
  &--top,
  &--top-alt {
    margin-bottom: 18px !important;
    line-height: initial;

    .el-form-item__label {
      justify-content: flex-start;
      margin-bottom: 8px;
      width: auto !important;
      height: auto !important;
      text-align: left;
      line-height: 22px;
    }
  }

  &--top {
    display: block;
  }

  &--top-alt {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;

    .el-form-item__content {
      flex-grow: 0;
    }
  }

  &__content {
    > .el-row {
      flex-grow: 1;
    }
  }

  &__tinymce {
    width: 100%;

    .tox-tinymce {
      border-radius: 0;
    }
  }
}
.info-row {
  margin: 0 0 18px;
  display: flex;
  font-size: 14px;

  &__label {
    padding-right: 12px;
    width: $labelWidth;
    text-align: right;
    color: var(--el-text-color-regular);
  }

  &__value {
    width: calc(100% - $labelWidth);
  }
}

.el-tabs {
  margin: 0 0 18px;
  width: 100%;

  &--border-card {
    margin: 0;
  }

  &__item {
    padding: 0 10px !important;
  }

  &__header {
    background: var(--el-border-color-light);

    .el-tabs__item {
      border-bottom-color: var(--el-border-color-light) !important;
      background: #f4f3ef;

      &.is-active {
        border-bottom-color: transparent !important;
        position: relative;
        &::after {
          content: '';
          display: block;
          border-top: 1px solid #f4f3ef;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }

      &.has-error {
        color: var(--el-color-danger);
      }
    }
  }
  &__item {
    &:hover,
    &.is-active {
      .el-tabs__item-label--with-pricelist::before {
        background: var(--el-color-primary);
      }
    }
    &-label {
      position: relative;
      &--with-pricelist::before {
        content: '';
        display: block;
        margin-left: -2px;
        border-radius: 50%;
        width: 4px;
        height: 4px;
        background: var(--el-text-color-primary);
        position: absolute;
        top: 100%;
        left: 50%;
        transition: background var(--el-transition-duration);
      }
    }
  }
  &__content {
    margin: -15px 0 0;
    padding: 20px;
    border: 1px solid var(--el-border-color-light);
    border-top: 0;

    .el-tabs--border-card > & {
      margin: 0;
      border: 0;
    }
  }
}

.el-table .el-input-number--small.priority {
  width: 100%;
}
div.phone {
  white-space: nowrap;
}

.el-drawer {
  &__header {
    margin-bottom: 20px;
  }

  &__title {
    font-size: 18px;
    line-height: 40px;
  }

  &__body {
    background: #f4f3ef;
  }
}

.agents-list {
  padding: 0;
}

.agent-item {
  position: relative;
  list-style-type: none;
  padding-left: 25px;
  border-left: 1px solid #BCB8B3;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: -5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #BCB8B3;
  }

  &:before {
    top: -5px;
  }

  &:after {
    bottom: -5px;
  }
}

.agent-item + .agent-item {
  margin-top: 35px;
}

.agent-subitem + .agent-subitem {
  margin-top: 20px;
}

.agent-subitem {
  display: flex;

  .icomoon {
    width: 18px;
    margin-right: 15px;
    font-size: 13px;
    color: #BCB8B3;
    text-align: center;
  }

  .icon-agents {
    font-size: 20px;
  }

  .icon-mail {
    margin-top: 5px;
  }

  .icon-agencies {
    font-size: 19px;
  }

  .name {
    color: #000;
    font-weight: 700;
  }

  .email {
    text-decoration: underline;
  }

  .agency_city {
    color: #BCB8B3;
  }
}

.history-list {
  .color {
    color: #51bcda;
  }

  .time {
    color: #6e6e6e;
  }
}

.price-row {
  display: flex;
  margin-left: -4px;
  width: calc(100% + 4px);

  &__input,
  &__currency {
    margin-left: 4px;
  }

  &__input {
    position: relative;

    .el-input__wrapper {
      padding-left: 1px !important;
      padding-right: 1px !important;
    }

    .el-input__inner {
      padding-left: 0;
      padding-right: 0;
      text-align: center !important;
    }
  }

  &__comment {
    display: block;
    border: 0;
    border-radius: 0;
    width: 100%;
    font-size: .8em;
    line-height: 1em;
    text-align: center;
    color: #adafb6;
    background: transparent;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    outline: none;
    &:focus {
      color: #212529;
    }
  }
}
.el-date-editor .el-input__wrapper {
  .el-icon {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .el-input__prefix,
  .el-input__suffix {
    width: 30px;
  }
}
</style>
